@import "./styles/color.scss";

.main {
  width: 98%;
  // height: 100vh;
  height: calc(100vh - 64px);
  background: #fff;
  position: relative;
  margin: 0 auto;

  .tabs {
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 80;
    width: 100%;
    padding-bottom: 20px;
    font-size: 24px;

    .tab {
      background: $main;
      flex-grow: 1;
      text-align: center;
      color: #fff;
      text-decoration: none;
      padding: 3px 0;
      border-right: 1px solid #fff;
      font-weight: 500;

      &:nth-child(7) {
        border-right: none;
      }

      &.active {
        border-bottom: 4px solid $secondary;
      }
    }
  }
}