@import "~/styles/color.scss";
@import "~/styles/reusable.scss";

@mixin flexStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analyze {
  min-width: 49%;
  margin: 5px;
  border: 1px solid #3c3838;
  overflow-y: scroll;
  text-align: start;
  :global(.ant-spin-nested-loading) {
    height: 100%;
    :global(.ant-spin) {
      max-height: none;
    }
  }
  .details {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    > div {
      width: 98%;
      margin: 1%;
    }
  }
  :global(.ant-collapse-item) {
    margin-bottom: 20px;
    border: 1px solid $border !important;
    border-radius: 5px !important;
    :global(.ant-collapse-header:hover) {
      color: #ac8730;
    }
  }
  > div {
    padding: 10px;
  }
  > h2 {
    padding-left: 10px;
    position: relative;
    > :global(.anticon) {
      position: absolute;
      top: calc(50% - 12px);
      font-size: 24px;
    }
  }
  .isOk {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: $articleHint;
    font-size: 18px;
    font-weight: 500;
    :global(.ant-select) {
      min-width: 140px;
      margin-left: 3px;
      :global(.ant-select-selector) {
        border: 1px solid #8e3700;
        background-color: #d8e3f5;
        &:hover {
          border: 1px solid #8e3700 !important;
        }
        :global(.ant-select-selection-item) {
          color: #d10000;
          font-weight: 600;
        }
      }
      :global(.ant-select-arrow) {
        color: #7c006c;
      }
    }
  }
  .evaluate {
    border: 1px solid #112c54;
    border-radius: 10px;
    background-color: #fff5f4;
    padding: 0 15px 15px;
    margin: 30px 10px;
    > p {
      color: $articleHint;
    }
  }
  // 圆环
  .interaction {
    margin: 0 auto;
    @include flexStyle;
    padding: 70px 20px;
    // 三层圆环
    .ring {
      width: 400px;
      height: 400px;
      border-radius: 50%;
      border: 1px dashed black;
      position: relative;
      .innerCircle {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 1px dashed black;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .center {
          width: 80px;
          height: 80px;
          text-align: center;
          border-radius: 50%;
          border: 1px solid black;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          @include flexStyle;
          color: #3871ca;
          font-weight: 600;
          transition: all 0.7s ease 1s;
          cursor: pointer;
          &:hover {
            width: 85px;
            height: 85px;
          }
        }
        .inner {
          &:nth-of-type(2) {
            transform: rotate(90deg);
            transform-origin: 20px 120px;
            > span {
              transform: rotate(269deg);
            }
          }
          &:nth-of-type(3) {
            transform: rotate(180deg);
            transform-origin: 20px 120px;
            > span {
              transform: rotate(180deg);
            }
          }
          &:nth-of-type(4) {
            transform: rotate(270deg);
            transform-origin: 20px 120px;
            > span {
              transform: rotate(90deg);
            }
          }
        }
      }
      .outer {
        &:nth-of-type(2) {
          transform: rotate(45deg);
          transform-origin: 20px 220px;
          > span {
            transform: rotate(315deg);
          }
        }
        &:nth-of-type(3) {
          transform: rotate(90deg);
          transform-origin: 20px 220px;
          > span {
            transform: rotate(270deg);
          }
        }
        &:nth-of-type(4) {
          transform: rotate(135deg);
          transform-origin: 20px 220px;
          > span {
            transform: rotate(227deg);
          }
        }
        &:nth-of-type(5) {
          transform: rotate(180deg);
          transform-origin: 20px 220px;
          > span {
            transform: rotate(180deg);
          }
        }
        &:nth-of-type(6) {
          transform: rotate(225deg);
          transform-origin: 20px 220px;
          > span {
            transform: rotate(135deg);
          }
        }
        &:nth-of-type(7) {
          transform: rotate(270deg);
          transform-origin: 20px 220px;
          > span {
            transform: rotate(90deg);
          }
        }
        &:nth-of-type(8) {
          transform: rotate(315deg);
          transform-origin: 20px 220px;
          > span {
            transform: rotate(45deg);
          }
        }
      }
      .inner,
      .outer {
        width: 50px;
        height: 50px;
        position: absolute;
        background-color: white;
        border: 1px solid black;
        border-radius: 50%;
        text-align: center;
        left: 50%;
        margin-left: -20px;
        margin-top: -20px;
        @include flexStyle();
        transition: all 0.7s ease 1s;
        cursor: pointer;
        &:hover {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
